import styles from './FeaturedFluid.module.scss'
import React from 'react'
import {
  Row,
  Col,
  Container,
  RawHtml,
  FancyShow,
  handleImgixUrl
} from '@kogk/common'
import Img from 'gatsby-image'

const left = { order: { lg: 1 } }
const right = { order: { lg: 2 } }

const alternateProps = alignment => {
  return alignment === 'image_on_left' ? [left, right] : [right, left]
}

export default ({ label: imageAlignment, caption, html, image, ...props }) => {
  const fluid = image?.url && handleImgixUrl(image.url).gatsbyImageFluid()
  const [first, second] = alternateProps(imageAlignment)

  return (
    <FancyShow>
      <section className='py-2'>
        <Container>
          <Row className='align-items-start flex-column-reverse flex-lg-row'>

            <Col col={{ lg: 6 }} className='pt-2 pt-lg-0' {...first}>
              <FancyShow>
                <RawHtml html={html} className='article parag-small' />
              </FancyShow>
            </Col>

            {fluid && (
              <Col col={{ lg: 6 }} {...second}>
                <Img loading='lazy' alt={image.alt} fluid={fluid} />
                <p className='font-italic pt-1 parag-small'>{caption}</p>
              </Col>
            )}
          </Row>
        </Container>
      </section>
    </FancyShow>
  )
}
